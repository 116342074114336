import React from "react";
import "./styles.scss";
import LiteYouTubeEmbed from "react-lite-youtube-embed";

export default () => (
  <div className="testimonials">
    <h2>Join 10,000+ successful organizations</h2>
    <LiteYouTubeEmbed
      id="4eHa96eqj7w"
      title="Client Testimonial"
      params={
        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      }
    />
  </div>
);
