import React from "react";
import "./styles.scss";
import Header from "../../components/Landers/Header";
import Testimonials from "../../components/Testimonials";
import { Helmet } from "react-helmet";
import Phone from "../../images/Contact/phone.svg";
import Email from "../../images/Contact/email.svg";

const steps = [
  "Our product specialist will get in touch with you ",
  "We’ll understand your requirements and offer customized solution",
  "Onboard. Verify. Repeat!",
];

export default () => (
  <>
    <Helmet>
      <title>Thank you for signing up with SpringVerify</title>
      <meta
        name="description"
        content="Thank you for trusting SpringVerify. Our product specialist will be reaching out to you to discuss your requirements for a fruitful onboarding."
      />
      <link rel="canonical" href="https://in.springverify.com/thank-you/" />
    </Helmet>
    <div className="thank-you">
      <div className="thank-you-Inner">
        <Header isThankYouPage />
        <div className="thankYouHeader">
          <h1>THANK YOU!</h1>
        </div>
        <section className="whats-next">
          <h2>What’s next?</h2>
          <div className="steps">
            {steps.map((step, i) => (
              <div key={i}>
                <img
                  data-src={`https://assets-sv-in.gumlet.io/thank-you/step${
                    i + 1
                  }.svg`}
                  alt="step"
                />
                <p>{step}</p>
              </div>
            ))}
          </div>
        </section>
        <div className="banner">
          <p>
            In the meantime, feel free to check out our{" "}
            <a href="https://www.springworks.in/blog/employee-background-check-guide/">
              Guide
            </a>{" "}
            to stay up-to-date with SpringVerify.
          </p>
        </div>
      </div>
      <Testimonials />
      <div className="GetInTouchContent">
        <h5>Feel free to Contact Us</h5>
        <span>
          <img src={Phone} />
          <a href="tel:+919019906005" className="contactNo">
            +91-9019906005
          </a>{" "}
        </span>
        <span>
          <img src={Email} />
          <a href="mailto:sales@springverify.com">
            sales@springverify.com
          </a>{" "}
        </span>
      </div>
    </div>
  </>
);
